export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home',
    },
    //Note: 09/26/2024 Load Confirmation changed to My Loads for UI's only
    {
        text: 'My Loads',
        path: '/load-confirmation',
        icon: 'selectall'
    },
    {
        text: 'Reports',
        icon: 'doc',
        items: [
            {
                text: 'Reconcile Accuracy',
                path: '/reconcile'
            }
        ]
    },
    //Note: 05/04/2023 Prefferred Route changed to Designated Route for UI's only
    {
        text: 'Designated Route',
        path: '/preferredRoute',
        icon: 'map',
    },
    {
        text: 'DTI Portal',
        path: '/driverPortal',
        icon: 'group',
    },
    {
        text: 'Expenses',
        path: '/expenseapproval',
        icon: 'money',
    },
    {
        text: 'Temperature Integrity',
        icon: 'doc',
        onClick: () => window.open(process.env.REACT_APP_DRIVER_DTI_DRIVER_TEMPERATURE_INTEGRITY_E_FORM_PATH)
    },
    {
        text: 'Logout',
        path: '/logout',
        icon: 'icon ion-log-out',
    },
];