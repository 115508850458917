import React, { FC } from "react";
import "devextreme/data/odata/store";
import { HeaderBox } from "components/load-confirmation";
import { Stop } from "types";
import "./company-notes-card.scss"

type Props = {
  stop: any;
};

const CompanyNotesCard: FC<Props> = ({ stop }) => {
  return stop && (stop.directions || stop.generalNotes || stop.safetyNotes) ? (
    <div className={`main-container-item-container`}>
      <HeaderBox headerTitle={"Company Notes"} changed={false} />
      <table className="company-notes-table">
        <tbody>
          {stop.lastMileDirections && (
            <React.Fragment>
              <tr>
                <th className="company-notes-table-header">Directions:</th>
              </tr>
              <tr>
                <td className="company-notes-table-text">
                  <pre>{stop.lastMileDirections}</pre>
                </td>
              </tr>
            </React.Fragment>
          )}
          {stop.generalNotes && (
            <React.Fragment>
              <tr>
                <th className="company-notes-table-header">General Notes:</th>
              </tr>
              <tr>
                <td className="company-notes-table-text">
                  <pre>{stop.generalNotes}</pre>
                </td>
              </tr>
            </React.Fragment>
          )}
          {stop.safetyNotes && (
            <React.Fragment>
              <tr>
                <th className="company-notes-table-header">Safety Notes:</th>
              </tr>
              <tr>
                <td className="company-notes-table-text">
                  <pre>{stop.safetyNotes}</pre>
                </td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default CompanyNotesCard;
