import React, { useEffect, useState } from "react";
import CreateExpenseHeader from "components/CreateExpenseHeader/CreateExpenseHeader";
import CreatedExpenseGrid from "components/CreatedExpenseGrid/CreatedExpenseGrid";
import CreateNewExpenseGrid from "components/CreateNewExpenseGrid/CreateNewExpenseGrid";
import LoadPanelComponent from "components/LoadPanelComponent/LoadPanelComponent";
import { useHistory } from "react-router-dom";
import { useExpenseApproval } from "services";
import notify from "devextreme/ui/notify";
import PopupComponent from "components/PopupComponent/PopupComponent";
import { OverLimitExpenseResponse, ValidateExpenseResponse } from "types";
import ValidationDataGrid from "components/ValidationDataGrid/ValidationDataGrid";
import { useScreenSize } from "../../utils/media-query";
import useExpenseApprovalService from "services/use-expense-approval";

const CreateExpense = () => {
  const history = useHistory();
  const screenSize = useScreenSize();
  const {
    getUserProfileId,
    addImage,
    deleteImage,
    getExpenseTypes,
    createExpense,
    deleteExpense,
    submitExpense,
    GetDriverProfileByUserId,
    validateExpenses,
  } = useExpenseApproval();

  const [uploadSpeedWarning, setUploadSpeedWarning] = useState<string | null>(null);
  const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState<number | null>(null);
  const { getImage } = useExpenseApprovalService();
  const [expenseTable, setExpenseTable] = useState<any>([]);
  const [duplicateExpenseData, setDuplicateExpenseData] = useState<
    ValidateExpenseResponse[]
  >([]);
  const [overlimitExpenseData, setOverlimitExpenseData] = useState<
    OverLimitExpenseResponse[]
  >([]);
  const [showValidate, setShowValidate] = useState<boolean>(false);
  const [expenseId, setExpenseId] = useState<any>(null);
  const [loaderParams, setLoaderParams] = useState<any>({
    loadPanelVisible: false,
    showIndicator: true,
    shading: true,
    showPane: true,
    positionId: "",
  });

  const [state, setState] = useState<any>({
    formData: {},
    multiple: false,
    uploadMode: "instantly",
    accept: "image/*",
    currentUser: "",
    imageProfileId: null,
    masterExpenseTypesData: [],
    expenseTypeOptions: {},
  });

  const [isCommentRequired, setIsCommentRequired] = useState(false)
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [receiptPopupVisible, setReceiptPopupVisible] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (state.formData && state.formData.description) {
      const expenseTypeId = state.masterExpenseTypesData.find(
        (elem: any) => elem.description === state.formData.description
      );
      setIsCommentRequired(expenseTypeId?.commentRequired ?? false);
    }
  }, [
    state.formData,
    state.formData.description,
    state.masterExpenseTypesData,
  ]);

  const hideInfo = () => {
    setPopupVisible(false);
  };

  const [imgState, setImgState] = useState<any>({
    selectedFile: [],
    imageProfileId: null,
  });

  const buttonOptions = {
    text: "SAVE",
    type: "default",
    stylingMode: "outlined",
    useSubmitBehavior: true,
    icon: "save",
    width: screenSize.isXSmall ? "50px" : "16vw",
    elementAttr: { id: "create-new-expense-btn" },
    disabled: !(imgState.selectedFile.length > 0),
  };

  const getImageData: any = async (imageId: any) => await getImage(imageId);

  const handleImagePreview = async (imageProfileId: string) => {
    const response: string = await getImageData(imageProfileId);
    if (response && response.length > 0) {
      setState({
        ...state,
        baseImageValue: response,
        canShowReceipt: true,
      });
      setReceiptPopupVisible(true);
    }
  };

  const handleImageUpload = (e: any) => {
    if (
      e.target &&
      e.target.files &&
      e.target.files[0] &&
      e.target.files[0] != null &&
      e.target.files[0].name.length > 0
    ) {
      e.preventDefault();
      speedCheck(e.target);
      handleUploadImage(e.target);
    }
  };

  const handleUploadImage = async (data: any) => {
    const imageFormData: any = new FormData();
    const userId: any = await getUserProfileId();
    await imageFormData.append("userId", userId.id);
    await imageFormData.append("imageFile", data.files[0]);
    setLoaderParams({
      ...loaderParams,
      loadPanelVisible: true,
      positionId: ".dx-last-row",
    });
    const response: any = await addImage(imageFormData);
    if (response && response.isSuccess === true) {
      setLoaderParams({
        ...loaderParams,
        loadPanelVisible: false,
        positionId: "",
      });
      setImgState({
        imageProfileId: response.id,
        selectedFile: data.files,
      });
      notify(
        {
          message: "Image Uploaded successfully!!",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "success",
        3000
      );
    }
  };

  const speedCheck = async (data: any) => {
    const file = data.files[0];
    const uploadStartTime = Date.now();
    const fileSizeMB = file.size / (1024 * 1024);

    let uploadedMB = 0;
    const interval = setInterval(() => {
      const timeElapsed = (Date.now() - uploadStartTime) / 1000;
      const uploadSpeedMBps = uploadedMB / timeElapsed;
      const uploadSpeedMbps = uploadSpeedMBps * 8;

      const remainingDataMB = fileSizeMB - uploadedMB;
      const estimatedTime = remainingDataMB / uploadSpeedMBps;

      if (uploadSpeedMbps < 10) {
        setUploadSpeedWarning(`Your internet speed is very low (${uploadSpeedMbps.toFixed(2)} Mbps). Uploading images may take longer than expected.`);
        setEstimatedTimeRemaining(Math.max(estimatedTime, 0)); 
      } else {
        setUploadSpeedWarning(null);
        setEstimatedTimeRemaining(null);
      }

      if (uploadedMB >= fileSizeMB) {
        clearInterval(interval);
      } else {
        uploadedMB += fileSizeMB * 0.1;
      }
    }, 1000);
  };

  const getDriverExpenseTypes = async () => {
    const response: any = await getExpenseTypes();
    setState({
      ...state,
      masterExpenseTypesData: response,
      expenseTypeOptions: {
        items: response.map((expense: any) => expense.description),
        value: state.formData.description,
      },
    });
  };

  const initialize = () => {
    getDriverExpenseTypes();
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleBackNavigation = () => {
    history.push("/expenseapproval");
  };

  const handleSubmitExpense = async () => {
    const submitExpenseForm = expenseTable.map((expense: any) => {
      return {
        id: expense.id,
        comments:
          expense.comments.length !== 0
            ? [
                {
                  id: expense.comments[expense.comments.length - 1]["id"],
                  expenseId:
                    expense.comments[expense.comments.length - 1]["expenseId"],
                  comment:
                    expense.comments[expense.comments.length - 1]["comment"],
                },
              ]
            : [],
      };
    });
    const userId: any = await getUserProfileId();
    setLoaderParams({
      ...loaderParams,
      loadPanelVisible: true,
      positionId: ".content",
    });
    const response = await submitExpense(submitExpenseForm, userId.id);
    if (!response) return;
    if (response) {
      setLoaderParams({
        ...loaderParams,
        loadPanelVisible: false,
        positionId: "",
      });
      notify(
        {
          message: "Expenses have been Submitted successfully!!",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "success",
        3000
      );
    }
    history.push("/expenseapproval");
  };

  const createNewExpense = async (tempObj: any, userId: any) => {
    await setLoaderParams({
      ...loaderParams,
      loadPanelVisible: true,
      positionId: ".dx-datagrid-content",
    });
    const response: any = await createExpense(tempObj, userId.id);
    if (response && response.expenseStatus.description === "Created") {
      await setLoaderParams({
        ...loaderParams,
        loadPanelVisible: false,
        positionId: "",
      });

      await setExpenseTable([
        ...expenseTable,
        {
          ...response,
          comment: response.comments[response.comments.length - 1]["comment"],
        },
      ]);
      notify(
        {
          message: "Expense Created successfully!!",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "success",
        3000
      );
      setState({ ...state, formData: {} });
      setImgState({ ...imgState, selectedFile: [] });
    }
  };

  const closeValidate = () => {
    setShowValidate(false);
    setPopupVisible(false);
  };

  const closeValidateButtonOptions = {
    text: "Close",
    onClick: closeValidate,
  };

  const createNewExpenseByPass = async () => {
    const userId: any = await getUserProfileId();
    const driverRegionCode = await GetDriverProfileByUserId(userId.id);
    let tempExpId: any = {};
    if (state.formData && state.formData.description) {
      const expenseTypeId = state.masterExpenseTypesData.find(
        (elem: any) => elem.description === state.formData.description
      );
      tempExpId = expenseTypeId;
    }

    let tempObj: any = {
      id: 0,
      driverEmployeeId: userId.driverEmployeeId,
      driverRegionCode: driverRegionCode.regionCode,
      driverDC: driverRegionCode.driverDC,
      expenseTypeId: tempExpId.id,
      requestedAmount: state.formData.requestedAmount,
      receiptDate: state.formData.receiptDate,
      imageProfileId: imgState.imageProfileId,
      comments:
        state.formData.comment.length > 0
          ? [
              {
                id: 0,
                expenseTypeId: tempExpId.id,
                comment: state.formData.comment,
              },
            ]
          : [],
    };
    setLoaderParams({
      ...loaderParams,
      loadPanelVisible: true,
      positionId: ".dx-datagrid-rowsview",
    });
    createNewExpense(tempObj, userId);
    setShowValidate(false);
    setPopupVisible(false);
  };

  const validatePopupOptions = {
    text: "Proceed",
    onClick: createNewExpenseByPass,
    type: "default",
    stylingMode: "default",
  };

  const handleCreateNewExpense = async (e: any) => {
    e.preventDefault();
    const userId: any = await getUserProfileId();
    const driverProfile = await GetDriverProfileByUserId(userId.id);
    let tempExpId: any = {};
    if (state.formData && state.formData.description) {
      const expenseTypeId = state.masterExpenseTypesData.find(
        (elem: any) => elem.description === state.formData.description
      );
      tempExpId = expenseTypeId;
    }

    let tempObj: any = {
      id: 0,
      driverEmployeeId: userId.driverEmployeeId,
      driverRegionCode: driverProfile.regionCode,
      driverDC: driverProfile.driverDC,
      expenseTypeId: tempExpId.id,
      requestedAmount: state.formData.requestedAmount,
      receiptDate: state.formData.receiptDate,
      imageProfileId: imgState.imageProfileId,
      comments:
        state?.formData?.comment?.length > 0 ||
        state?.formData?.comment !== null ||
        state?.formData?.comment !== undefined
          ? [
              {
                id: 0,
                expenseTypeId: tempExpId.id,
                comment: state.formData.comment,
              },
            ]
          : [],
    };
    const response: any = await validateExpenses(tempObj);
    if (
      response?.duplicateExpenses?.length === 0 &&
      response?.overLimitExpenses?.length === 0
    ) {
      createNewExpense(tempObj, userId);
    } else {
      if (response?.duplicateExpenses?.length > 0) {
        setDuplicateExpenseData(response?.duplicateExpenses);
      } else if (response?.overLimitExpenses?.length > 0) {
        setOverlimitExpenseData(response?.overLimitExpenses);
      }
      setShowValidate(true);
      setPopupVisible(true);
    }
  };

  const hideLoadPanel = () => {
    setLoaderParams({
      ...loaderParams,
      loadPanelVisible: false,
    });
  };

  function handleDeleteExpense(e: any) {
    const isCanceled = new Promise(async (resolve, reject) => {
      const dataValue: any = await getUserProfileId();
      if (dataValue && dataValue.id) {
        const userId = dataValue?.id;
        setLoaderParams({
          ...loaderParams,
          loadPanelVisible: true,
          positionId: ".dx-datagrid-rowsview",
        });
        const response: any = await deleteExpense({ id: expenseId }, userId);
        if (response && response.isDeleted === true) {
          resolve(false);
          setLoaderParams({
            ...loaderParams,
            loadPanelVisible: false,
            positionId: "",
          });
          setPopupVisible(false);
          setExpenseTable(
            expenseTable.filter((item: any) => item.id !== expenseId)
          );
          notify(
            {
              message: "Expense Deleted successfully!!",
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "warning",
            3000
          );
        } else {
          reject("failure");
        }
      }
    });
    e.cancel = isCanceled;
  }

  const handleClearImage = async () => {
    const userId: any = await getUserProfileId();
    const response: any = await deleteImage({
      id: imgState.imageProfileId,
      userId: userId.id,
    });
    if (response && response.isDeleted === true) {
      setImgState({
        selectedFile: [],
        imageProfileId: null,
      });
      setUploadSpeedWarning(null);
      setEstimatedTimeRemaining(null)
      notify(
        {
          message: "Image Deleted successfully!!",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "success",
        3000
      );
    }
  };

  const openDeleteConfirm = async (e: any) => {
    await setExpenseId(e.key);
    setPopupVisible(true);
  };

  const hideReceiptInfo = () => {
    setReceiptPopupVisible(false);
  };

  const closeReceiptImageButtonOptions = {
    text: "Close",
    onClick: hideReceiptInfo,
  };

  return (
    <div>
      <CreateExpenseHeader
        handleBackNavigation={handleBackNavigation}
        handleSubmitExpense={handleSubmitExpense}
        expenseTable={expenseTable}
      />
      <hr />
      <CreateNewExpenseGrid
        formData={state.formData}
        handleCreateNewExpense={handleCreateNewExpense}
        buttonOptions={buttonOptions}
        positionOptions={state.expenseTypeOptions}
        handleImageUpload={handleImageUpload}
        imgState={imgState}
        hideReceiptInfo={hideReceiptInfo}
        closeButtonOptions={closeReceiptImageButtonOptions}
        canShowReceipt={state.canShowReceipt}
        baseImageValue={state.baseImageValue}
        handleClearImage={handleClearImage}
        handleImagePreview={handleImagePreview}
        popupVisible={receiptPopupVisible}
        estimatedTimeRemaining={estimatedTimeRemaining}
        uploadSpeedWarning={uploadSpeedWarning}
        isCommentRequired={isCommentRequired}
      />

      <CreatedExpenseGrid
        expenseTable={expenseTable}
        handleDeleteExpense={handleDeleteExpense}
        popupVisible={popupVisible}
        hideInfo={hideInfo}
        showValidate={showValidate}
        openDeleteConfirm={openDeleteConfirm}
      />

      <LoadPanelComponent
        hideLoadPanel={hideLoadPanel}
        loadPanelVisible={loaderParams.loadPanelVisible}
        showIndicator={loaderParams.showIndicator}
        shading={loaderParams.shading}
        showPane={loaderParams.showPane}
        positionId={loaderParams.positionId}
      />
      {showValidate && (
        <PopupComponent
          submitButtonOptions={validatePopupOptions}
          popupVisible={popupVisible}
          hideInfo={hideInfo}
          closeButtonOptions={closeValidateButtonOptions}
          title="Validation Result"
        >
          <ValidationDataGrid
            duplicateExpenseData={duplicateExpenseData}
            overlimitExpenseData={overlimitExpenseData}
          />
        </PopupComponent>
      )}
    </div>
  );
};

export default CreateExpense;
