import { FC, useMemo, useState } from "react";

import Form, {
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
  RequiredRule,
  RangeRule,
} from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import "./CreateNewExpenseGrid.scss";
import { useScreenSize } from "../../utils/media-query";
import "devextreme-react/text-area";

import PopupComponent from "components/PopupComponent/PopupComponent";
import ImagePreview from "components/ImagePreview/ImagePreview";
import { formatTime } from "helpers/Utilities";

interface Props {
  formData?: any;
  handleCreateNewExpense?: (e?: any) => void;
  buttonOptions?: any;
  positionOptions?: any;
  handleImagePreview?: (e: any) => void;
  imgState?: any;
  popupVisible?: boolean;
  closeButtonOptions?: any;
  canShowReceipt?: boolean;
  baseImageValue?: string;
  hideReceiptInfo?: (e?: any) => void;
  handleClearImage?: () => void;
  handleImageUpload?: (e: any) => void;
  uploadSpeedWarning?: any;
  estimatedTimeRemaining: any;
  isCommentRequired: boolean;
}

const CreateNewExpenseGrid: FC<Props> = ({
  formData,
  handleCreateNewExpense,
  buttonOptions,
  positionOptions,
  imgState,
  popupVisible,
  closeButtonOptions,
  canShowReceipt,
  baseImageValue,
  hideReceiptInfo,
  handleClearImage,
  handleImageUpload,
  handleImagePreview,
  uploadSpeedWarning,
  estimatedTimeRemaining,
  isCommentRequired,
}) => {
  const screenSize = useScreenSize();

  const renderImageComponent = () => {
    return (
      <div id="img-upload-div">
        {imgState?.selectedFile && imgState?.selectedFile.length > 0 ? (
          <div className="">
            <Button
              icon={"image"}
              onClick={() =>
                handleImagePreview &&
                handleImagePreview(imgState.imageProfileId)
              }
            />
            <Button
              icon={"trash"}
              disabled={false}
              hint="Clear the Uploaded Image"
              onClick={handleClearImage}
            />
          </div>
        ) : (
          <div>
            <label id="img-upload-label" htmlFor="file">
              Upload
            </label>
            <input
              onChange={handleImageUpload}
              type="file"
              accept="*/*"
              name="file"
              id="file"
              className="inputfile"
            />
          </div>
        )}
          {uploadSpeedWarning && (
              <div className="upload-warning">{uploadSpeedWarning}</div>
            )}

            {estimatedTimeRemaining !== null && (
              <div className="upload-time-estimate">
                Estimated time remaining: {formatTime(estimatedTimeRemaining)}
              </div>
            )}
      </div>
    );
  };

  const calculateMinCol = () => {
    return screenSize.isXSmall ? 135 : 150;
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <p className="driver-info">Create New Expense For Submission</p>
  
      <div className="card-div">
        <div>
          <form onSubmit={handleCreateNewExpense}>
            <Form
              minColWidth={calculateMinCol()}
              colCount={1}
              screenByWidth={(w: any) => (w < 720 ? "sm" : "md")}
              formData={formData}>

              <GroupItem colCount={1}>
                <SimpleItem
                  dataField="description"
                  editorType="dxSelectBox"
                  editorOptions={{
                    ...positionOptions,
                    searchEnabled: false,
                  }}>
                  <RequiredRule />
                  <Label text={"Expense Type"} />
                </SimpleItem>
              </GroupItem>
  
              <GroupItem colCount={"auto"}>
                <GroupItem>
                  <SimpleItem
                    dataField="receiptDate"
                    editorType="dxDateBox"
                    editorOptions={{
                      max: new Date(),
                    }}>
                    <RequiredRule />
                    <Label text={"Receipt Date"} />
                  </SimpleItem>
                </GroupItem>
  
                <GroupItem>
                  <SimpleItem
                    dataField="requestedAmount"
                    editorType="dxNumberBox"
                    editorOptions={{
                      precision: 2,
                      format: "$#,##0.00",
                    }}>
                    <Label text={"Request Amount"} />
                    <RangeRule
                      min={1}
                      message="Requested Amount cannot be zero"
                    />
                    <RequiredRule />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>

              <GroupItem colCount={1}>
                <SimpleItem dataField="comment">
                  {isCommentRequired && <RequiredRule />}
                  <Label text={"Comment"} />
                </SimpleItem>
              </GroupItem>

              <GroupItem colCount={2}>
                <SimpleItem component={renderImageComponent}>
                  <Label text={"Receipt Upload"} />
                </SimpleItem>
                <ButtonItem
                  horizontalAlignment="center"
                  buttonOptions={buttonOptions}
                />
              </GroupItem>

            </Form>
  
            {canShowReceipt && (
              <PopupComponent
                popupVisible={popupVisible}
                hideInfo={hideReceiptInfo}
                closeButtonOptions={closeButtonOptions}
                title="RECEIPT PREVIEW"
              >
                <ImagePreview baseImageValue={baseImageValue} />
              </PopupComponent>
            )}
          </form>
        </div>
      </div>
    </div>
  );
  
  
};

export default CreateNewExpenseGrid;
